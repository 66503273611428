/* eslint-disable no-undefined */
import {
  ClockCircleOutlined,
  DollarOutlined,
  EyeOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  PercentageOutlined,
  TruckOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Tooltip,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { t } from 'i18next';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import bidDeclined from '../../assets/bid-declined.svg';
import firstPlace from '../../assets/bid-first_place.svg';
import fourthPlace from '../../assets/bid-fourth_place.svg';
import secondPlace from '../../assets/bid-second_place.svg';
import thirdPlace from '../../assets/bid_third_place.svg';
import StyledButton from '../../components/Common/StyledButton';
import StyledInput from '../../components/Common/StyledInput';
import { Loading } from '../../components/Loading';
import { BidStatus } from '../../helpers/enums';
import {
  formatNumberForTowDecimalPlaces,
  formatNumberToOrdinal,
  formatValuesToThousands,
} from '../../helpers/FormatValues';
import { userUtils } from '../../helpers/useUserUtils';
import { getURI, oneAlert } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import theme from '../../styles/theme';
import BidCard from './BidCard';
import { PopConfirmUpdateBid } from './PopConfirmUpdateBid';
import * as S from './styles';
import { BidPartNumber, IBidCard, IbidCardItemDetails, IComment } from './types';

export function useBidResponse() {
  const { getUserCompanyCurrent } = userUtils();
  const [form] = Form.useForm();
  const history = useHistory();
  const { pathname } = useLocation();
  const [visibleModal, setVisibleModal] = useState(false);
  const bidId = useRef('');
  const currentUser = getUserCompanyCurrent();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRight, setIsLoadingRight] = useState(false);
  const [bidCards, setBidCards] = useState<Array<IBidCard>>([]);
  const [bidItemSelected, setBidItemSelected] = useState<IbidCardItemDetails>();
  const [categorySelected, setCategorySelected] = useState('');
  const [statusBid, setStatusBid] = useState<BidStatus>();
  const completeSocketURL = bidId.current
    ? `${import.meta.env.VITE_API_WEBSOCKET}?channel=${bidId.current}`
    : null;

  const { sendJsonMessage, lastMessage, lastJsonMessage, sendMessage } = useWebSocket(
    completeSocketURL,
    {
      shouldReconnect: () => true,
      retryOnError: true,
    },
    !!completeSocketURL
  );

  const closeWebSocketConnection = (channel: string) => {
    sendJsonMessage({ action: 'unsubscribe', channel });
  };

  const handleConvertComments = (comments: Array<IComment> | null): string => {
    try {
      if (!comments) return '';
      return comments.reduce((reducedComment, comment) => {
        return comment.comment ? `${reducedComment} ${comment.comment}` : '';
      }, '');
    } catch (error) {
      console.log(Error);
      return '';
    }
  };

  const handleSetFormFieldsData = () => {
    !bidItemSelected && form.resetFields();
    form.setFieldsValue({
      grossPrice: formatNumberForTowDecimalPlaces(bidItemSelected?.grossPrice),
      capacity: bidItemSelected?.capacity,
      leadTime: bidItemSelected?.leadTime,
      declineBid: bidItemSelected?.decline,
      comments: handleConvertComments(bidItemSelected?.comments || []),
    });
  };

  const handleRenderProjectsSpecsBidItemSelected = (
    values: Array<string>,
    isBlueClass?: boolean
  ): ReactNode => {
    return values.map((value) => (
      <span key={value} className={isBlueClass ? 'blue' : ''}>
        {value || '-'}
      </span>
    ));
  };

  const handleRenderPartNumbersBidItemSelected = (partNumbers: Array<BidPartNumber>): ReactNode => {
    return partNumbers.map((partNumber) => (
      <Tooltip placement="bottom" title={partNumber.description} key={crypto.randomUUID()}>
        <span>{partNumber.partNumber || '-'}</span>
      </Tooltip>
    ));
  };

  const handleAddStatusOnBidCardDetails = (
    bidCardDetails: IbidCardItemDetails
  ): IbidCardItemDetails => {
    try {
      if (bidCardDetails.decline) {
        return {
          ...bidCardDetails,
          status: 'DECLINED',
        };
      }

      if (bidCardDetails.rankingPosition && bidCardDetails.rankingPosition > 0) {
        return {
          ...bidCardDetails,
          status: 'ANSWERED',
        };
      }

      return {
        ...bidCardDetails,
        status: 'PENDING',
      };
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
      console.log(error);
      return bidCardDetails;
    }
  };

  const handleGetBidCardDetails = async (altGroup?: string) => {
    try {
      setIsLoadingRight(true);
      const { data, status } = await api.get(
        getURI(`${services.rfq}/bid/supplier/${bidId.current}`, { altGroup })
      );
      status === 200 && setBidItemSelected(handleAddStatusOnBidCardDetails(data));
    } catch (error) {
      oneAlert({
        type: 'error',
        message: t('toast.errorOnList'),
      });
      console.log(error);
    } finally {
      setIsLoadingRight(false);
    }
  };

  //atualizando qual dos cards foi selecionado
  const handleUpdateStatusCardOnSelected = (id: string) => {
    const newBidCards = bidCards.map((bidCard) => {
      return {
        ...bidCard,
        groupedByCategories: bidCard.groupedByCategories.map((bidCardItem) => {
          if (bidCardItem.id === id) {
            setCategorySelected(bidCard.category);
            return { ...bidCardItem, selected: true };
          }
          return { ...bidCardItem, selected: false };
        }),
      };
    });
    setBidCards(newBidCards);
  };

  const handleClickBidCard = (idCard: string, altGroup: string) => {
    handleUpdateStatusCardOnSelected(idCard);
    handleGetBidCardDetails(altGroup);
  };

  const handleRenderBidGroupsWithCards = (): ReactNode => {
    return bidCards.map((bidCard) => (
      <BidCard key={bidCard.category} bidCardOnSelect={handleClickBidCard} bidCard={bidCard} />
    ));
  };

  const handleRenderImgForPositionOnBid = (position: number | null): ReactNode => {
    if (!position) return null;

    if (position === 1)
      return <img src={firstPlace} alt={t('pages.bidResponse.altImages.firstPlace')} />;
    if (position === 2)
      return <img src={secondPlace} alt={t('pages.bidResponse.altImages.secondPlace')} />;
    if (position === 3)
      return <img src={thirdPlace} alt={t('pages.bidResponse.altImages.thirdPlace')} />;

    return <img src={fourthPlace} alt={t('pages.bidResponse.altImages.fourthPlace')} />;
  };

  const handleRenderMsgForPositionBid = (position: number | null): ReactNode => {
    if (!position) return null;
    if (position === 1) {
      return (
        <>
          <span className="title">{t('pages.bidResponse.messages.titleFirstPlace')}</span>
          <span className="subTitle">{t('pages.bidResponse.messages.subTitleFirstPlace')}</span>
        </>
      );
    }
    if (position === 2) {
      return (
        <>
          <span className="title">{t('pages.bidResponse.messages.titleSecondPlace')}</span>
          <span className="subTitle">{t('pages.bidResponse.messages.subTitleSecondPLace')}</span>
        </>
      );
    }
    if (position === 3) {
      return (
        <>
          <span className="title">{t('pages.bidResponse.messages.titleThirdPlace')}</span>
          <span className="subTitle">{t('pages.bidResponse.messages.subTitleThirdPlace')}</span>
        </>
      );
    }

    return (
      <>
        <span className="title">{t('pages.bidResponse.messages.titleOtherPlaces')}</span>
        <span className="subTitle">{t('pages.bidResponse.messages.subTitleOtherPlaces')}</span>
      </>
    );
  };

  const handleValidatePriceInterval = (value: number): boolean => {
    try {
      if (!bidItemSelected?.grossPrice) return true;
      // se não tiver price interval, valida se novo lance é menor que o anterior
      if (!bidItemSelected?.priceInterval) {
        if (Number(value) > bidItemSelected.grossPrice) return false;
        return true;
      }
      //validando se o valor está dentro do intervalo de lances cadastrado no bid

      const lastGrossGap = (Number(bidItemSelected.grossPrice) - Number(value)).toFixed(2);

      return (
        Number(lastGrossGap) > bidItemSelected.priceInterval ||
        Number(lastGrossGap) === bidItemSelected.priceInterval
      );
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleValidateGrossPrice = (value: number): boolean => {
    try {
      const priceRegex = /^\d+\.\d{2}$/;
      //validando se o número informado possui duas casas decimais
      if (!priceRegex.test(value.toString())) return false;
      //caso não exista valor de starting price não valida
      if (!bidItemSelected?.startingPrice) return true;
      //validando valor informado maior que starting price caso exista
      if (value > Number(bidItemSelected?.startingPrice)) return false;
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleValidateCapacity = (value: number): boolean => {
    try {
      //validando se o número informado está entre 0 e 100 e se possui duas casas decimais
      const priceRegex = /^(100|[1-9]?\d)$/;
      if (!priceRegex.test(value.toString())) return false;
      if (value < 1 || value > 100) return false;
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleValidateLeadTime = (value: number): boolean => {
    try {
      //caso o dado informado não seja inteiro ou seja menor que zero
      if (!Number.isInteger(value) || value < 0) return false;
      if (value > 999) return false;
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleSetValuesDefaultOnLoadPage = (category: string) => {
    handleGetBidCardDetails();
    setCategorySelected(category);
  };

  const handleAddIdAndSelectOnBidCard = (bidCardsReceived: Array<IBidCard>): Array<IBidCard> => {
    try {
      return bidCardsReceived.map((bidCard: IBidCard, indexBidCard: number) => {
        return {
          ...bidCard,
          groupedByCategories: bidCard.groupedByCategories.map(
            (bidCardItem, indexCardItem: number) => {
              //caso seja o primeiro item do primeiro card busca os detalhes do bid para a tela já iniciar com dados carregados
              indexBidCard === 0 &&
                indexCardItem === 0 &&
                handleSetValuesDefaultOnLoadPage(bidCard.category);
              return {
                ...bidCardItem,
                //gerando id para os cards localmente uma vez que esse dado não e recebido pelo back
                id: (indexCardItem + bidCardItem.altGroup).toString(),
                //caso seja o primeiro card do primeiro grupo seta como selecionado
                selected: indexBidCard === 0 && indexCardItem === 0,
                category: bidCard.category,
              };
            }
          ),
        };
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleGetBidData = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(
        `${services.rfq}/bid/supplier/categories/${bidId.current}`
      );
      if (status === 200) {
        setStatusBid(data.status);
        setBidCards(handleAddIdAndSelectOnBidCard(data.categories));
      }

      status === 204 && setBidCards([]);
    } catch (error) {
      console.log(error);
      oneAlert('error', t('toast.errorOnList'));
      setBidCards([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateBid = (status: IbidCardItemDetails['status']) => {
    setBidItemSelected((oldBid) => {
      if (!oldBid) return oldBid;
      return {
        ...oldBid,
        status,
      };
    });
  };

  const handleSaveBidResponse = async () => {
    await form.validateFields().then(async () => {
      try {
        const values = form.getFieldsValue();
        setIsLoading(true);
        const { status } = await api.put(`${services.rfq}/bid/supplier`, {
          capacity: values.capacity,
          comments: values.comments
            ? {
                name: currentUser.currentUser || currentUser.currentUserNickName,
                comment: values.comments,
              }
            : null,
          decline: values.declineBid,
          grossPrice: values.grossPrice,
          leadTime: values.leadTime,
          id: bidItemSelected?.id,
        });

        if (status === 200) {
          oneAlert('success', t('pages.bidResponse.messages.saveSuccess'));
          handleGetBidData();
          setBidItemSelected(undefined);
        }
      } catch (error: any) {
        console.log(error);
        oneAlert('error', error.message || t('toast.errorOnSave'));
      }
    });
  };

  const handleValidateRemainingAttempts = () => {
    if (bidItemSelected?.attemptLimit === bidItemSelected?.currentAttempt) return true;
  };

  const handleValidateStatusBidForSendOffer = (): boolean => {
    if (!statusBid) return true;
    if (statusBid === 'ROUND_OPEN' || statusBid === 'QUOTATION_SENT') return false;
    return true;
  };

  const handleClearFieldsWhenBidDecline = (isDeclined: boolean) => {
    if (isDeclined) {
      form.resetFields();
      form.setFieldValue('declineBid', true);
    }
  };

  const handleCreateMessageFromAlert = (): string => {
    if (bidItemSelected?.attemptLimit) {
      const message =
        (bidItemSelected.attemptLimit ?? 0) > (bidItemSelected.currentAttempt ?? 0)
          ? t('pages.bidResponse.messages.usingAttempts', {
              currentAttempt: (bidItemSelected.currentAttempt ?? 0) + 1,
              totalAttempts: bidItemSelected.attemptLimit,
            })
          : t('pages.bidResponse.messages.usingAllAttempts', {
              currentAttempt: bidItemSelected.currentAttempt,
              totalAttempts: bidItemSelected.attemptLimit,
            });
      return message;
    }

    return '';
  };

  const handleRenderAlertRemainingAttempts = (): ReactNode => {
    if (!bidItemSelected?.attemptLimit) return null;
    return (
      <Alert
        type="error"
        message={handleCreateMessageFromAlert()}
        showIcon
        icon={<InfoCircleFilled />}
        style={{ marginBottom: '2.5rem' }}
      />
    );
  };

  const handleRenderAttemptsLimit = (): ReactNode => {
    return (
      <Alert
        type="error"
        message={t('pages.bidResponse.messages.usedAllAttempts')}
        showIcon
        icon={<InfoCircleFilled />}
        style={{ marginBottom: '2.5rem' }}
      />
    );
  };

  const handleValidadeStatusBidForUpdateOffer = (): boolean => {
    try {
      if (handleValidateStatusBidForSendOffer()) return true;
      if (!bidItemSelected?.attemptLimit) return false;
      if ((bidItemSelected.attemptLimit ?? 0) <= (bidItemSelected.currentAttempt ?? 0)) return true;
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleRenderBidDetails = (): ReactNode => {
    if (isLoadingRight) {
      return (
        <S.ContentRight>
          <Loading />
        </S.ContentRight>
      );
    }

    if (bidItemSelected?.status === 'DECLINED') {
      return (
        <S.ContentRightImages>
          <S.ContainerImages>
            <img src={bidDeclined} alt="" width={289} />
            <span className="title">{t('pages.bidResponse.messages.titleDeclined')}</span>
            <span className="subTitle">{t('pages.bidResponse.messages.subTitleDeclined')}</span>
          </S.ContainerImages>
          <S.ContainerFooterOnlyOneButton>
            <StyledButton
              variant="primary"
              disabled={!handleValidateStatusBidForSendOffer()}
              onClick={() => {
                handleUpdateBid('PENDING');
              }}
            >
              {t('pages.bidResponse.buttons.updateBid')}
            </StyledButton>
          </S.ContainerFooterOnlyOneButton>
        </S.ContentRightImages>
      );
    }

    if (bidItemSelected?.status === 'ANSWERED') {
      return (
        <S.ContentRightImages>
          <S.ContainerImages>
            {bidItemSelected.attemptLimit === bidItemSelected.currentAttempt &&
              handleRenderAttemptsLimit()}
            {handleRenderImgForPositionOnBid(bidItemSelected?.rankingPosition)}
            {handleRenderMsgForPositionBid(bidItemSelected?.rankingPosition)}
          </S.ContainerImages>
          <S.ContainerFooterOnlyOneButton>
            <StyledButton
              disabled={handleValidateStatusBidForSendOffer() || handleValidateRemainingAttempts()}
              variant="primary"
              onClick={() => {
                handleUpdateBid('UPDATING');
              }}
            >
              {t('pages.bidResponse.buttons.updateBid')}
            </StyledButton>
          </S.ContainerFooterOnlyOneButton>
        </S.ContentRightImages>
      );
    }

    if (bidItemSelected?.status === 'PENDING' || bidItemSelected?.status === 'UPDATING') {
      return (
        <S.ContentRight>
          {bidItemSelected.status === 'UPDATING' && handleRenderAlertRemainingAttempts()}
          <S.ContainerCardsRightContainer>
            <S.CardInsideRightContent>
              <Row className="row">
                <span>{t('pages.bidResponse.labels.project')}</span>
                {handleRenderProjectsSpecsBidItemSelected(bidItemSelected?.projects || [], true)}
              </Row>
              <Row className="row">
                <span>{t('pages.bidResponse.labels.spec')}</span>
                {handleRenderProjectsSpecsBidItemSelected(bidItemSelected?.specifications || [])}
              </Row>
              <Row className="row">
                <span>{t('pages.bidResponse.labels.partNumber')}</span>
                {handleRenderPartNumbersBidItemSelected(bidItemSelected?.partNumbers || [])}
              </Row>
            </S.CardInsideRightContent>
            <S.MiddleCardContainer>
              <Row className="row">
                <span>{t('pages.bidResponse.labels.from')}</span>
                <b>{bidItemSelected?.from}</b>
                <TruckOutlined style={{ color: theme.colorsDesignSystem.blue, fontSize: 18 }} />
                <span>{t('pages.bidResponse.labels.to')}</span>
                <b>{bidItemSelected?.to}</b>
              </Row>
              <Row className="rowCenter">
                <ClockCircleOutlined />
                <span>{t('pages.bidResponse.labels.demandPeriod')}</span>
              </Row>
              <Row className="rowCenter">
                <p>{bidItemSelected?.demandPeriod}</p>
              </Row>
            </S.MiddleCardContainer>
            <S.RightCardContainer>
              <Row className="row">
                <DollarOutlined />
                <span>{t('pages.bidResponse.labels.startPrice')}</span>
              </Row>
              <Row className="row">
                <span className="blue">{bidItemSelected?.startingPrice}</span>
              </Row>
              <Row className="row">
                <span>{t('pages.bidResponse.labels.totalDemand')}</span>
                <b>{formatValuesToThousands(bidItemSelected?.totalDemand) || '-'}</b>
              </Row>
            </S.RightCardContainer>
          </S.ContainerCardsRightContainer>

          {bidItemSelected.rankingPositionAnterior && bidItemSelected.lastPrice && (
            <S.RoundContainer>
              <span className="title">{t('pages.bidResponse.previousRound.title')}</span>
              <Flex align="center">
                <Flex gap={12} align="center">
                  <span className="label">{t('pages.bidResponse.previousRound.price')}</span>
                  <span className="value">{bidItemSelected.lastPrice}</span>
                </Flex>
                <Divider type="vertical" />
                <Flex gap={12} align="center">
                  <span className="label">{t('pages.bidResponse.previousRound.ranking')}</span>
                  <S.Classification>
                    {formatNumberToOrdinal(bidItemSelected.rankingPositionAnterior || 0)}
                  </S.Classification>
                </Flex>
              </Flex>
            </S.RoundContainer>
          )}
          <S.FormContainer>
            <Form form={form} onFinish={handleSaveBidResponse}>
              {(!bidItemSelected.rankingPosition || bidItemSelected.rankingPosition === 0) && (
                <Form.Item name="declineBid" valuePropName="checked">
                  <Checkbox
                    onChange={(e) => {
                      handleClearFieldsWhenBidDecline(e.target.checked);
                    }}
                  >
                    {t('pages.bidResponse.labels.decline')}
                  </Checkbox>
                </Form.Item>
              )}
              <S.ContainerInputsForm>
                <Col>
                  <Row>
                    <span className="labels">{t('pages.bidResponse.labels.gross')}</span>
                    <Tooltip placement="top" title={t('pages.bidResponse.hints.gross')}>
                      <InfoCircleOutlined style={{ color: theme.colorsDesignSystem.primary }} />
                    </Tooltip>
                  </Row>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.declineBid !== currentValues.declineBid
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      //validando se o checkbox de declinar bid está selecionado
                      const declineIsChecked = getFieldValue('declineBid');
                      return (
                        <Form.Item
                          name="grossPrice"
                          rules={[
                            {
                              type: 'float',
                              validator: (_, value) => {
                                //caso não seja informado valor, mas o decline bid tenha sido habilitado, o campo pode ser enviado sem validação
                                if (!value && declineIsChecked) return Promise.resolve();

                                return value && !handleValidatePriceInterval(value)
                                  ? Promise.reject(
                                      new Error(
                                        t('pages.bidResponse.messages.validatePriceInterval', {
                                          value: bidItemSelected.priceInterval?.toFixed(2),
                                        })
                                      )
                                    )
                                  : value && handleValidateGrossPrice(value)
                                  ? Promise.resolve()
                                  : Promise.reject(new Error(t('common.validate.invalid')));
                              },
                            },
                          ]}
                        >
                          <StyledInput
                            disabled={declineIsChecked}
                            styles={{ minWidth: '11.438rem' }}
                            placeholder="Add Value"
                            autoComplete="off"
                            addonAfter={
                              <DollarOutlined
                                style={{ color: theme.colorsDesignSystem.primary, fontSize: 12 }}
                              />
                            }
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col>
                  <Row>
                    <span className="labels">{t('pages.bidResponse.labels.capacity')}</span>
                    <Tooltip placement="top" title={t('pages.bidResponse.hints.capacity')}>
                      <InfoCircleOutlined style={{ color: theme.colorsDesignSystem.primary }} />
                    </Tooltip>
                  </Row>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.declineBid !== currentValues.declineBid
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const declineIsChecked = getFieldValue('declineBid');
                      return (
                        <Form.Item
                          name="capacity"
                          rules={[
                            {
                              type: 'float',
                              validator: (_, value) => {
                                //caso não seja informado valor, mas o decline bid tenha sido habilitado, o campo pode ser enviado sem validação
                                if (!value && declineIsChecked) return Promise.resolve();
                                return value && handleValidateCapacity(value)
                                  ? Promise.resolve()
                                  : Promise.reject(new Error(t('common.validate.invalid')));
                              },
                            },
                          ]}
                        >
                          <StyledInput
                            styles={{ minWidth: '11.438rem' }}
                            placeholder="Add Value"
                            disabled={declineIsChecked}
                            addonAfter={
                              <PercentageOutlined
                                style={{ color: theme.colorsDesignSystem.primary, fontSize: 12 }}
                              />
                            }
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col>
                  <Row>
                    <span className="labels">{t('pages.bidResponse.labels.leadTime')}</span>
                    <Tooltip placement="top" title={t('pages.bidResponse.hints.leadTime')}>
                      <InfoCircleOutlined style={{ color: theme.colorsDesignSystem.primary }} />
                    </Tooltip>
                  </Row>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.declineBid !== currentValues.declineBid
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const declineIsChecked = getFieldValue('declineBid');
                      return (
                        <Form.Item
                          name="leadTime"
                          rules={[
                            {
                              validator: (_, value) => {
                                if (!value && declineIsChecked) return Promise.resolve();
                                return value && handleValidateLeadTime(value)
                                  ? Promise.resolve()
                                  : Promise.reject(new Error(t('common.validate.invalid')));
                              },
                            },
                          ]}
                        >
                          <InputNumber
                            disabled={declineIsChecked}
                            maxLength={3}
                            style={{
                              minWidth: '7.813rem',
                              marginTop: '2px',
                            }}
                            placeholder="Add Value"
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </S.ContainerInputsForm>
              <S.ContainerComment>
                <Col>
                  <Row>
                    <span className="labels">{t('pages.bidResponse.labels.comments')}</span>
                  </Row>
                </Col>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.declineBid !== currentValues.declineBid
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const declineIsChecked = getFieldValue('declineBid');
                    return (
                      <Form.Item name="comments">
                        <TextArea
                          disabled={declineIsChecked}
                          maxLength={400}
                          style={{ minHeight: '5rem', padding: '1rem' }}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </S.ContainerComment>
              <S.ContainerFooter>
                <>
                  {bidItemSelected.status === 'UPDATING' && (
                    <S.ContainerFooterOnlyOneButton>
                      <Popconfirm
                        onClick={(e) => e.stopPropagation()}
                        placement="topLeft"
                        icon={null}
                        onConfirm={handleSaveBidResponse}
                        okButtonProps={{
                          style: {
                            marginRight: '0.5rem',
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem',
                            height: '1.5rem',
                            marginBottom: '1rem',
                          },
                        }}
                        cancelButtonProps={{
                          style: {
                            height: '1.5rem',
                            marginBottom: '1rem',
                            color: theme.colorsDesignSystem.primary,
                            marginRight: '20rem',
                          },
                        }}
                        okText={t('common.confirm')}
                        cancelText={t('common.cancel')}
                        title={<PopConfirmUpdateBid />}
                      >
                        <StyledButton
                          disabled={handleValidadeStatusBidForUpdateOffer()}
                          variant="primary"
                        >
                          {t('pages.bidResponse.buttons.saveUpdate')}
                        </StyledButton>
                      </Popconfirm>
                    </S.ContainerFooterOnlyOneButton>
                  )}

                  {bidItemSelected.status === 'PENDING' && (
                    <>
                      <StyledButton
                        iconLeft={<EyeOutlined />}
                        variant="secondary"
                        onClick={() => {
                          setVisibleModal(true);
                        }}
                      >
                        {t('pages.bidResponse.buttons.details')}
                      </StyledButton>
                      <Form.Item style={{ margin: 0 }}>
                        <StyledButton
                          htmlType="submit"
                          variant="primary"
                          disabled={handleValidateStatusBidForSendOffer()}
                        >
                          {t('pages.bidResponse.buttons.place')}
                        </StyledButton>
                      </Form.Item>
                    </>
                  )}
                </>
              </S.ContainerFooter>
            </Form>
          </S.FormContainer>
        </S.ContentRight>
      );
    }
  };

  const handleCountItensOnBid = (): string => {
    let total = 0;
    bidCards.forEach((bid) => {
      total += bid.groupedByCategories.length;
    });
    return total.toString();
  };

  const handleCloseModal = () => {
    setVisibleModal(false);
  };

  useEffect(() => {
    handleSetFormFieldsData();
  }, [bidItemSelected]);

  const verifyAltGroups = (updatedAltGroup: string) => {
    if (!bidItemSelected || bidItemSelected.status !== 'UPDATING') {
      const altGroups = bidCards.flatMap((bidCard) =>
        bidCard.groupedByCategories.map((category) => category.altGroup)
      );
      if (altGroups.includes(updatedAltGroup)) {
        handleGetBidData();
      }
    }
  };

  useEffect(() => {
    if (lastMessage !== null || lastJsonMessage !== null) {
      verifyAltGroups(lastJsonMessage.altGroup);
    }
  }, [lastMessage, lastJsonMessage]);

  const onNavigateOut = history.listen((location) => {
    if (location.pathname !== pathname) {
      closeWebSocketConnection(bidId.current);
    }
  });

  useEffect(() => {
    return () => {
      onNavigateOut();
    };
  }, []);

  return {
    isLoading,
    bidItemSelected,
    currentUser,
    visibleModal,
    bidCards,
    bidId,
    categorySelected,
    handleRenderProjectsSpecsBidItemSelected,
    handleRenderPartNumbersBidItemSelected,
    handleRenderBidGroupsWithCards,
    handleRenderBidDetails,
    handleCountItensOnBid,
    handleUpdateStatusCardOnSelected,
    handleGetBidData,
    handleCloseModal,
    handleGetBidCardDetails,
  };
}
